import React, { useState, useEffect } from 'react';
import { Search, Close } from '../../assets/icons';
import PropTypes from 'prop-types';
import { Button, Input } from '../shared';
import { BUTTON } from '../../constants';

export const SearchBar = ({ search, setSearch, searchTerm, setSearchTerm, onDefaultSearch }) => {
  const [lastSearchedTerm, setLastSearchedTerm] = useState(''); // Track the last searched term
  const [searchTriggered, setSearchTriggered] = useState(false); // Tracks if the search button was clicked
  const [isSearchPerformed, setIsSearchPerformed] = useState(false); // Tracks if a search was performed

  // Trigger the default API call when searchTerm is cleared and a search was performed
  useEffect(() => {
    if (searchTerm === '' && isSearchPerformed) {
      setSearch(false); // Reset the search state
      setLastSearchedTerm(null); // Reset the last searched term
      setIsSearchPerformed(false); // Reset the search performed state
      onDefaultSearch(); // Trigger the default API call
    }
  }, [searchTerm, isSearchPerformed, setSearch, onDefaultSearch]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setSearchTriggered(false); // Reset when the input changes
  };

  const searchHandler = () => {
    setSearch(false); // Reset search to false first
    setTimeout(() => {
      setSearch(true); // Trigger search again
      setLastSearchedTerm(searchTerm); // Update the last searched term
      setSearchTriggered(true); // Mark the search as triggered
      setIsSearchPerformed(true); // Mark that a search has been performed
    }, 0);
  };

  const clearSearch = () => {
    setSearchTerm(''); // Clear the input value
  };

  const isSearchDisabled =
    !searchTerm || // Disable if search term is empty
    searchTerm?.trim()?.length === 0 || // Disable if search term has no characters
    searchTerm === lastSearchedTerm || // Disable if the search term matches the last searched term
    (searchTriggered && search); // Disable if a search is already triggered

  return (
    <div className="mb-3">
      <div className="container-search-bar w-100 d-flex align-items-center gap-2">
        <div className="bg-white position-relative d-flex align-items-center w-100 justify-content-start searchbar-area">
          <div className="search-btn2 position-absolute border-0">
            <Search />
          </div>
          <Input
            type="text"
            className="border-0 w-100 d-flex align-items-center justify-content-center"
            placeholder={''}
            value={searchTerm}
            onChange={handleInputChange}
          />
          {searchTerm && (
            <div
              onClick={clearSearch}
              className="search-btn2 border-0"
              role="button"
              aria-label="Clear search">
              <Close />
            </div>
          )}
        </div>
        <Button
          clickHandler={searchHandler}
          className={`search-btn d-flex align-items-center ${
            isSearchDisabled ? 'disabled' : ''
          } justify-content-center px-3`}
          disabled={isSearchDisabled}>
          {BUTTON.SEARCH}
        </Button>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  search: PropTypes.bool.isRequired,
  setSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  onDefaultSearch: PropTypes.func.isRequired, // Function to trigger the default API call
};
