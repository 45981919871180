import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '../shared';

// eslint-disable-next-line react/display-name
const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <input
    ref={ref}
    value={value}
    onClick={onClick}
    readOnly // Prevents touch keyboard from opening
    className="bill-date-picker"
    placeholder="YYYY-MM-DD"
  />
));

export const DatePickerBox = ({ value, setValue, clickHandler, showSave, btnName }) => {
  const dateChangeHandler = (date) => {
    setValue(date);
  };
  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };
  return (
    <>
      <DatePicker
        selected={value}
        onChange={dateChangeHandler}
        dateFormat="yyyy-MM-dd"
        showYearDropdown
        showMonthDropdown
        scrollableYearDropdown
        customInput={<CustomInput />} // Use custom input to set `readonly`
        dayClassName={(date) => (isToday(date) ? 'highlight-today' : 'custom-day')} // Assign a class to each day
      />
      {showSave === 'hide' || (
        <Button
          className={`border-0 blue-btn d-flex align-items-center ${!showSave ? 'disabled' : ''} justify-content-center py-2 px-3`}
          clickHandler={clickHandler}
          disabled={!showSave}>
          {btnName}
        </Button>
      )}
    </>
  );
};

export default DatePickerBox;
