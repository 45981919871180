import React, { useEffect, useRef, useState } from 'react';
import { getBatchQty } from './ManufacturingContent';
import { apiDateFormat, dateFormatChanger, popup, showDate } from '../../../../utils';
import { MfgTable } from './MfgTable';
import {
  BLP_COLUMNS,
  BUTTON,
  HEADING,
  TEXT,
  POPUP_TYPE,
  POPUP_MSG,
  PLACEHOLDER,
  STATUS,
  DROPDOWN,
} from '../../../../constants';
import { DropDownArrow2 } from '../../../../assets/icons';
import { Button, Input, PopUpBox } from '../../../shared';
import { DropDown } from '../../../common';
import {
  batchCreation,
  batchQtyUpdate,
  batchDelete,
  getPrint,
  readyForPack,
  readyForDye,
  getPackageList,
} from '../../../../service/manufacturing';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../../redux';
import { useDebounce } from '../../../../hooks';

function isPositiveInteger(value) {
  const positiveIntegerRegex = /^[1-9]\d*$/;
    // Ensure the value is not a decimal and matches the regex
  return typeof value === "number" && Number.isInteger(value) && positiveIntegerRegex.test(value.toString());
}

const CreateBatch = ({ lot, setEditBoxState, setReload }) => {
  const [batchQty, setBatchQty] = useState('');
  const batchQuantity = useRef();
  const dispatch = useDispatch();
  const [selectedLot, setSelectedLot] = useState(
    lot?.selected_lot?.length ? lot?.selected_lot[0] : [],
  );
  const [availableLot, setAvailableLot] = useState([]);
  useEffect(() => {
    batchQuantity?.current?.focus();
  }, []);

  const handleBatchCreator = () => {
    if (selectedLot.name) {
      if (batchQty < 1) {
        popup(POPUP_TYPE.ERR, POPUP_MSG.INVALID_QTY_MSG);
      } else {
        if (batchQty > lot.total_qty - lot.ready_qty) {
          popup(POPUP_TYPE.ERR, POPUP_MSG.QTY_EXCEED);
        }
        dispatch(setLoaderVisibility(true));
        batchCreation({
          data: {
            move_id: [lot.move_id],
            data: {
              lot: selectedLot.name,
              is_default_lot: lot?.selected_lot[0]?.name ? true : false,
              order_qty: +batchQuantity.current.value,
              batch_no: `${lot.lot_no}-${+lot.batch.length + 1}`,
            },
          },
        })
          .then((response) =>
            response.data.length
              ? popup(POPUP_TYPE.SUCCESS, response.message)
              : popup(POPUP_TYPE.ERR, response.message),
          )
          .catch((error) => {
            popup(error.message);
          });
        setReload(true);
        setEditBoxState(0);
      }
    } else {
      popup(POPUP_TYPE.ERR, POPUP_MSG.SELECT_LOT);
    }
  };
  const handleListClick = (lot) => {
    setSelectedLot(lot);
  };
  useEffect(() => {
    setAvailableLot(lot?.lots?.filter((lot) => lot.id !== selectedLot.id));
  }, [selectedLot]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    let availableLots = [];
    if (value) {
      availableLots = lot.lots
        .filter((lot) => lot.name.toLowerCase().includes(value))
        .filter((lt) => lt.id !== selectedLot.id);
    } else {
      availableLots = lot?.lots?.filter((lot) => lot.id !== selectedLot.id);
    }
    availableLots.length > 0
      ? setAvailableLot(availableLots)
      : setAvailableLot('No Lots Available');
  };
  return (
    <div className="edit-container p-4">
      <h5 className="text-center mb-4 mt-0">{HEADING.BATCH_CREATION}</h5>
      <div className="row mx-0 edit-set-box">
        <div className="col-4">{TEXT.BATCH_NO}</div>
        <div className="col-8">{`${lot.lot_no} - ${lot.batch.length + 1}`} </div>
        <div className="col-4">{TEXT.ORDER_QTY}</div>
        <input
          ref={batchQuantity}
          type="number"
          className="col-8"
          placeholder={`${PLACEHOLDER.REMAINING_QTY} : ${lot.total_qty - lot.ready_qty}`}
          value={batchQty}
          max={lot.total_qty - getBatchQty(lot)}
          min={1}
          onChange={(e) => isPositiveInteger(+e.target.value)?setBatchQty(+e.target.value):setBatchQty('')}
        />
        <div className="custom-dropdown-4 p-0">
          <div className="dropdown m-0 w-100">
            <button
              className="dropdown-toggle bg-white w-100 d-flex align-items-center justify-content-between"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              {Object.keys(selectedLot).length ? `Lot : ${selectedLot.name}` : 'Select Lot'}
              <DropDownArrow2 />
            </button>
            <div className="dropdown-menu p-0 w-100" aria-labelledby="dropdownMenuButton1">
              <div className="w-100 mt-2 px-1">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search.."
                  onChange={(e) => handleSearch(e)}
                />
              </div>
              <ul className="list-data-area w-100 m-0 p-0">
                {Array.isArray(availableLot) && availableLot.length > 0 ? (
                  availableLot.map((lot) => (
                    <li
                      key={lot.id}
                      onClick={() => {
                        handleListClick(lot);
                      }}>
                      <div className="dropdown-item d-flex align-items-center justify-content-start">
                        {lot.name}
                      </div>
                    </li>
                  ))
                ) : (
                  <li>
                    <div className="dropdown-item d-flex align-items-center justify-content-start">
                      {'No lots Available'}
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center gap-4 w-100 mt-4">
        <Button
          clickHandler={handleBatchCreator}
          className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
          {BUTTON.CREATE_BATCH}
        </Button>

        <Button
          clickHandler={() => {
            setEditBoxState(0);
          }}
          className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
          {BUTTON.CLOSE}
        </Button>
      </div>
    </div>
  );
};
const EditBatch = ({
  field,
  setField,
  selectedBatch,
  setEditBoxState,
  setReload,
  setBlpData,
  dyeing,
}) => {
  const cutQuantity = useRef();
  const sewQuantity = useRef();
  const pckQuantity = useRef();
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [cutQty, setCutQty] = useState(selectedBatch.cut_qty || '');
  const [cutDate, setCutDate] = useState(selectedBatch.cut_date);
  const [sewQty, setSewQty] = useState(selectedBatch.sew_qty || '');
  const [sewDate, setSewDate] = useState(selectedBatch.sew_date);
  const [pckQty, setPckQty] = useState(selectedBatch.pack_qty || '');
  const [pckDate, setPckDate] = useState(selectedBatch.pack_date);
  const [showPopup, setShowPopup] = useState(false);

  const dispatch = useDispatch();

  //  to focus on Field which need to edit
  useEffect(() => {
    if (field === STATUS.CUT) {
      cutQuantity.current.focus();
    } else if (field === STATUS.SEW) {
      sewQuantity.current.focus();
    } else if (field === STATUS.PACK) {
      pckQuantity.current.focus();
    }
  }, []);

  //  handle Cut quantity change
  const handleCutChange = (e) => {
    if(isPositiveInteger(+e.target.value)){
      setCutQty(+e.target.value);
      setField(STATUS.CUT);
    }
    else{
      setCutQty('');
    }
  };

  //  handle Sew quantity change
  const handleSewChange = (e) => {
    if(isPositiveInteger(+e.target.value)){
    setSewQty(+e.target.value);
    setField(STATUS.SEW);
    }
    else{
      setSewQty('');
    }
  };

  //  handle Pack quantity change
  const handlePckChange = (e) => {
    if(isPositiveInteger(+e.target.value)){
    setPckQty(+e.target.value);
    setField(STATUS.PACK);
    }
    else{
      setPckQty('');
    }
  };

  // handle submit
  // 1. if dyeing true and state is ready than call ready for dye
  // 2. else if dying false and state is ready than call ready for Pack
  // 3. else update quantity
  const handleSubmit = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    if (field === STATUS.READY && dyeing === false) {
      readyForPack({
        data: {
          batch_id: [selectedBatch.id],
        },
      })
        .then((response) => {
          if (response.data.length > 0) {
            popup(POPUP_TYPE.SUCCESS, response.message);
            setEditBoxState(3);
            setBlpData(response.data);
            setReload(true);
          } else {
            popup(POPUP_TYPE.ERR, response.message);
            setReload(true);
            setEditBoxState(0);
          }
        })
        .catch((err) => popup(POPUP_TYPE.ERR, err.message));
    }
    // eslint-disable-next-line
    else if (field === STATUS.READY && dyeing === true) {
      readyForDye({
        data: {
          batch_id: [selectedBatch.id],
        },
      })
        .then((response) => {
          popup(POPUP_TYPE.SUCCESS, response.message);
          setEditBoxState(0);
          setReload(true);
        })
        .catch((err) => popup(POPUP_TYPE.ERR, err.message));
    } else {
      setAllowUpdate(true);
    }
  }, 400);

  // handleBLPGenerator
  const handleBLPGenerator = useDebounce(() => {
    if (selectedBatch.state === 'packed') {
      getPackageList({
        data: {
          batch_id: [selectedBatch.id],
        },
      })
        .then((response) => {
          if (response.data.length > 0) {
            popup(POPUP_TYPE.SUCCESS, response.message);
            setEditBoxState(3);
            setBlpData(response.data);
          } else {
            popup(POPUP_TYPE.ERR, response.message);
            setEditBoxState(0);
          }
        })
        .catch((err) => popup(POPUP_TYPE.ERR, err.message));
    }
  }, 400);

  // to handle wrong qty input
  const wrongQty = ({ status, compare }) => {
    popup(POPUP_TYPE.ERR, POPUP_MSG.INVALID_QTY({ status, compare })), setAllowUpdate(false);
  };

  // data validation based on edited field
  const dataCheck = () => {
    let batchUpdate = {};
    if (field === STATUS.CUT) {
      cutQty > selectedBatch.order_qty || cutQty < 1
        ? wrongQty({ status: STATUS.CUT, compare: STATUS.ORDER })
        : (batchUpdate = {
            cut_qty: parseFloat(cutQty),
            cut_date: apiDateFormat(cutDate),
          });
    } else if (field === STATUS.SEW) {
      sewQty > selectedBatch.cut_qty || sewQty < 1
        ? wrongQty({ status: STATUS.SEW, compare: STATUS.CUT })
        : (batchUpdate = {
            sew_qty: parseFloat(sewQty),
            sew_date: apiDateFormat(sewDate),
          });
    } else if (field === STATUS.PACK) {
      pckQty > selectedBatch.sew_qty || pckQty < 1
        ? wrongQty({ status: STATUS.PACK, compare: STATUS.SEW })
        : (batchUpdate = {
            pack_qty: parseFloat(pckQty),
            pack_date: apiDateFormat(pckDate),
          });
    }
    return batchUpdate;
  };

  const handleBatchDelete = () => {
    dispatch(setLoaderVisibility(true));
    batchDelete({ data: { batch_id: selectedBatch.id } })
      .then((response) => popup(POPUP_TYPE.SUCCESS, response.message))
      .catch((err) => popup(POPUP_TYPE.SUCCESS, err.message));
    setReload(true);
    setEditBoxState(0);
  };
  //  if update allowed by handle Submit than perform data check and call API
  useEffect(() => {
    if (allowUpdate) {
      const batchUpdate = dataCheck();
      if (Object.keys(batchUpdate)?.length > 0) {
        dispatch(setLoaderVisibility(true));
        batchQtyUpdate({ data: { batch_id: [selectedBatch.id], data: batchUpdate } })
          .then((response) => popup(POPUP_TYPE.SUCCESS, response.message))
          .catch((err) => popup(POPUP_TYPE.SUCCESS, err.message));
        setReload(true);
        setEditBoxState(0);
      }
    }
  }, [allowUpdate]);
  return (
    <div className="edit-container p-4">
      <h5 className="text-center mb-4 mt-0">{HEADING.EDIT_BATCH}</h5>
      <div className="row mx-0 edit-set-box">
        <div className="col-4">{TEXT.BATCH_NO}</div>
        <div className="col-8">{selectedBatch.name}</div>
        <div className="col-4">{TEXT.ORDER_QTY}</div>
        <div className="col-8">{selectedBatch.order_qty}</div>
        <div className="col-4">{TEXT.CUT_QTY}</div>
        {/* Cutting part \/ */}
        {field === STATUS.CUT ? (
          <input
            ref={cutQuantity}
            type="number"
            value={cutQty}
            className="col-8"
            placeholder={selectedBatch.order_qty}
            min={1}
            onChange={handleCutChange}
          />
        ) : (
          <div className="col-8">{selectedBatch.cut_qty}</div>
        )}
        <div className="col-4">{TEXT.CUT_DATE}</div>
        {field === STATUS.CUT ? (
          <Input
            type="date"
            className="col-8"
            placeholder={PLACEHOLDER.ENTER_DATE}
            value={showDate(cutDate)}
            disabled={!selectedBatch.order_qty}
            onChange={(e) => setCutDate(e.target.value)}
          />
        ) : (
          <div className="col-8">{dateFormatChanger(cutDate)}</div>
        )}
        {/* Sew part \/ */}
        <div className="col-4">{TEXT.SEW_QTY}</div>
        {field === STATUS.SEW ? (
          <input
            ref={sewQuantity}
            type="number"
            value={sewQty}
            className="col-8"
            placeholder={selectedBatch.cut_qty}
            disabled={selectedBatch.sew_qty}
            onChange={handleSewChange}
          />
        ) : (
          <div className="col-8">{selectedBatch.sew_qty}</div>
        )}
        <div className="col-4">{TEXT.SEW_DATE}</div>
        {field === STATUS.SEW ? (
          <Input
            type="date"
            className="col-8"
            placeholder={PLACEHOLDER.ENTER_DATE}
            value={showDate(sewDate)}
            onChange={(e) => setSewDate(e.target.value)}
          />
        ) : (
          <div className="col-8">{dateFormatChanger(sewDate)}</div>
        )}

        {/* package part \/ */}
        {dyeing || <div className="col-4">{TEXT.PACKAGE_QTY}</div>}
        {dyeing ||
          (field === STATUS.PACK ? (
            <input
              ref={pckQuantity}
              type="number"
              value={pckQty}
              className="col-8"
              placeholder={selectedBatch.sew_qty}
              disabled={selectedBatch.pack_qty}
              onChange={handlePckChange}
            />
          ) : (
            <div className="col-8">{selectedBatch.pack_qty}</div>
          ))}
        {dyeing || <div className="col-4">{TEXT.PACKAGE_DATE}</div>}
        {dyeing ||
          (field === STATUS.PACK ? (
            <Input
              type="date"
              className="col-8"
              placeholder={PLACEHOLDER.ENTER_DATE}
              value={showDate(pckDate)}
              onChange={(e) => setPckDate(() => dateFormatChanger(e.target.value))}
            />
          ) : (
            <div className="col-8">{dateFormatChanger(pckDate)}</div>
          ))}
      </div>
      {/* Buttons */}
      <div className="d-flex align-items-center justify-content-center gap-4 w-100 mt-4">
        {selectedBatch.state !== 'packed' ? (
          <>
            {' '}
            <Button
              clickHandler={handleSubmit}
              className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
              {field === STATUS.READY && dyeing
                ? BUTTON.READY_FOR_DYEING
                : field === STATUS.READY && dyeing === false
                  ? BUTTON.READY_FOR_PACK
                  : BUTTON.SAVE}
            </Button>
            <Button
              clickHandler={() => setShowPopup(true)}
              className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
              {BUTTON.DELETE_BATCH}
            </Button>
          </>
        ) : (
          !dyeing && (
            <Button
              clickHandler={handleBLPGenerator}
              className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
              {BUTTON.GENERATE_BLP}
            </Button>
          )
        )}
        <Button
          clickHandler={() => setEditBoxState(0)}
          className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
          {BUTTON.CLOSE}
        </Button>
      </div>
      {showPopup && (
        <PopUpBox openPopUpBox={showPopup}>
          <DeleteBatchPopup apiCall={handleBatchDelete} setOpenConfirmationBox={setShowPopup} />
        </PopUpBox>
      )}
    </div>
  );
};

const DeleteBatchPopup = ({ apiCall, setOpenConfirmationBox }) => {
  const closePopUp = () => {
    setOpenConfirmationBox(false);
  };
  const handleApiCall = () => {
    setOpenConfirmationBox(false);
    apiCall();
  };
  return (
    <>
      <div className="d-flex m-1 ">
        <div className="w-25 modal-body py-0 d-flex justify-content-center align-items-center">
          <span className="d-flex justify-content-center align-items-center bg-info badge">!</span>
        </div>
        <div className="w-75 modal-body py-0">
          <h4 className=" text-center text-special mb-3 ">Delete Batch</h4>
          <p className="text-center text-special placeholder-color ">
            Are you sure to Delete Batch?
          </p>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CONFIRM}
          </Button>{' '}
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

export const BLPList = ({ data, setEditBoxState }) => {
  const dispatch = useDispatch();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [boxInfo, setBoxInfo] = useState({});
  const [printUrl, setPrintUrl] = useState('');
  const handlePrint = () => {
    setOpenPopUp(true);
  };
  const getPrintUrl = () => {
    getPrint({
      data: boxInfo,
    })
      .then((response) => {
        if (response.data.length > 0) {
          dispatch(setLoaderVisibility(false));
          setPrintUrl(response.data[0].split('?')[0]);
        } else {
          popup(POPUP_TYPE.ERR, response.message);
          dispatch(setLoaderVisibility(false));
        }
      })
      .catch((err) => popup(POPUP_TYPE.SUCCESS, err.message));
  };
  useEffect(() => {
    if (Object.keys(boxInfo).length) {
      dispatch(setLoaderVisibility(true));
      getPrintUrl();
    }
  }, [boxInfo]);

  useEffect(() => {
    if (printUrl) {
      window.open(printUrl, '_blank', 'noreferrer');
      setEditBoxState(0);
    }
  }, [printUrl]);

  return (
    <div className="edit-container p-4">
      <h5 className="text-center mb-4 mt-0">{BUTTON.GENERATE_BLP}</h5>
      <div className="w-100 table-3">
        <MfgTable column={BLP_COLUMNS} data={data} clickHandler={() => {}} />
      </div>
      {openPopUp && (
        <PopUpBox openPopUpBox={openPopUp}>
          <BoxInfo
            selectedPackage={data.map((pck) => pck.id)}
            setOpenPopUp={setOpenPopUp}
            setBoxInfo={setBoxInfo}
          />
        </PopUpBox>
      )}
      <div className="d-flex align-items-center justify-content-center w-100 mt-4">
        <Button
          clickHandler={handlePrint}
          className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
          {BUTTON.PRINT_LABEL}
        </Button>
      </div>
    </div>
  );
};

const BoxInfo = ({ selectedPackage, setOpenPopUp, setBoxInfo }) => {
  const [option, setOption] = useState(DROPDOWN.BOX_OPTION[0]);
  const boxInfo = {
    sizes: [option], // 12_4, 4_6
    package_types: ['single'], // single, mix
    package_ids: [...selectedPackage],
  };
  const handleBoxInfo = () => {
    setBoxInfo(boxInfo);
    setOpenPopUp(false);
  };
  return (
    <div className="p-3 ">
      <div className="d-flex flex-column gap-3  ms-4">
        <div className="d-flex justify-content-between ms-5 me-5">
          <label>{TEXT.BOX_SIZE}</label>
          <DropDown dropdownMenu={DROPDOWN.BOX_OPTION} value={option} setValue={setOption} />
        </div>
        <div className="d-flex justify-content-between ms-5 me-5">
          <label>{TEXT.BOX_TYPE}</label>
          <input
            className="w-50 ps-2"
            type="text"
            disabled={true}
            value={boxInfo.package_types[0]}
          />
        </div>
        <div className="d-flex justify-content-center gap-3">
          <Button
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3"
            clickHandler={handleBoxInfo}
            text={BUTTON.SAVE}></Button>
          <Button
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3"
            clickHandler={() => setOpenPopUp(false)}
            text={BUTTON.CANCEL}></Button>
        </div>
      </div>
    </div>
  );
};

export const BatchEditBox = ({
  field,
  setField,
  selectedBatch,
  lot,
  editBoxState,
  setEditBoxState,
  setLot,
  setReload,
}) => {
  const [blpData, setBlpData] = useState([]);
  return (
    <div className="w-100 py-4 editing-container">
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-lg-9 col-11">
          {editBoxState === 1 && (
            <CreateBatch
              lot={lot}
              setLot={setLot}
              editBoxState={editBoxState}
              setEditBoxState={setEditBoxState}
              selectedBatch={selectedBatch}
              setReload={setReload}
            />
          )}
          {editBoxState === 2 && (
            <EditBatch
              setLot={setLot}
              setEditBoxState={setEditBoxState}
              selectedBatch={selectedBatch}
              field={field}
              setField={setField}
              setReload={setReload}
              setBlpData={setBlpData}
              dyeing={lot.dyeing}
            />
          )}
          {editBoxState === 3 && (
            <BLPList
              data={blpData}
              selectedBatch={selectedBatch}
              setEditBoxState={setEditBoxState}
              // setPrintUrl={setPrintUrl}
            />
          )}
          {/* {editBoxState === 4 && <PrintScreen url={printUrl} setEditBoxState={setEditBoxState} />} */}
        </div>
      </div>
    </div>
  );
};
