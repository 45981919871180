import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  MFG_STATUS,
  DROPDOWN,
  TITLES,
  ROUTES,
  POPUP_TYPE,
  DATA_FIELD,
  BUTTON,
} from '../../../../constants';
import { DropDown, SearchBar, TitleBar } from '../../../common';
import { StatusBox } from './StatusBox';
import { GreenTile, RedTile, YellowTile } from './Tiles';
import { vendorLot } from '../../../../service';
import { useDebounce } from '../../../../hooks';
import { setLoaderVisibility } from '../../../../redux';
import { popup, manufacturingDataCompiler, reducedCalc } from '../../../../utils';
import { Button } from '../../../shared';

export const ManufacturingContent = () => {
  const [mfgData, setMfgData] = useState([]);
  const [sort1, setSort1] = useState('Preference');
  const [sort2, setSort2] = useState('Qty Status');
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sortingArray1, setSortingArray1] = useState([]);
  const [sortingArray2, setSortingArray2] = useState(DROPDOWN.MFG_SORT_2);
  const [sortedData, setSortedData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [jumpPage, setJumpPage] = useState('');

  const dispatch = useDispatch();

  const getManufacturingList = useDebounce((params = {}) => {
    dispatch(setLoaderVisibility(true));
    vendorLot({ data: { page_number: pageNumber, ...params } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        const records = response?.data?.records || [];
        if (records.length > 0) {
          const compiledData = manufacturingDataCompiler(records);
          setMfgData(compiledData);
          setPaginationData(response.data.params);

          if (!sortingArray1.includes(sort1)) {
            setSortingArray1(response?.data?.manufacture_priority);
          }

          if (!sortingArray2.includes(sort2)) {
            setSortingArray2(response?.data?.qty_status);
          }
        } else {
          setMfgData([]);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const getList = (additionalParams = {}) => {
    const params = {
      page_number: pageNumber,
      search: searchTerm
        ? {
            future_lot: searchTerm,
            ['product_id.default_code']: searchTerm,
          }
        : {},
      sort: {
        selection: {
          ...(sort1 != 'Preference'
            ? { manufacture_priority: sort1.toLowerCase().replaceAll(' ', '_') }
            : {}),
          ...(sort2 != 'Qty Status'
            ? {
                qty_status: sort2.toLowerCase().replaceAll(' ', '_'),
              }
            : {}),
        },
      },
      ...additionalParams,
    };
    getManufacturingList(params);
  };

  useEffect(() => {
    if (search) {
      setPageNumber(1);
      // setSearch(false);
      getList({ page_number: 1 });
    }
  }, [search]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    setPageNumber(1);
    getList({ page_number: 1 });
  }, [sort1, sort2]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getList();
  }, [pageNumber]);

  useEffect(() => {
    setSortedData(mfgData);
  }, [mfgData]);

  useEffect(() => {
    if (!searchTerm && search) {
      setPageNumber(1);
      setSearch(false);
      getList({ page_number: 1 });
    }
  }, [searchTerm]);

  const resetFilter = () => {
    setSort1('Preference');
    setSort2('Qty Status');
  };

  const handleJumpToPage = () => {
    const totalPages = Math.ceil(paginationData.total_records / 20);
    const targetPage = parseInt(jumpPage, 10);

    if (targetPage >= 1 && targetPage <= totalPages) {
      setPageNumber(targetPage);
    } else {
      popup(POPUP_TYPE.ERR, `Please enter a valid page number between 1 and ${totalPages}.`);
    }
  };

  return (
    <>
      <TitleBar title={[{ text: TITLES.MANUFACTURING, route: ROUTES.MANUFACTURING }]} />
      <StatusBox status={MFG_STATUS} />
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar
          search={search}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          setSearch={setSearch}
          onDefaultSearch={getList}
        />
        <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1} />
          <DropDown dropdownMenu={sortingArray2} value={sort2} setValue={setSort2} />
          {(sortingArray2.includes(sort2) || sortingArray1.includes(sort1)) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {mfgData.length > 0 && (
        <div className="pagination-container d-flex justify-content-between align-items-center mb-2">
          <h5 className="pagination-info">
            <span className="highlight">Records:</span>
            <span className="record-range">
              {paginationData.start} - {paginationData.end}
            </span>
            <span className="highlight">of</span>
            <span className="total-records">{paginationData.total_records}</span>
          </h5>

          <div className="pagination-controls d-flex align-items-center justify-content-end">
            <Button
              className={`blue-btn d-flex align-items-center justify-content-center px-3 me-2 ${
                paginationData.page === 1 ? 'disabled' : ''
              }`}
              clickHandler={() => setPageNumber((prev) => Math.max(prev - 1, 1))}
              disabled={paginationData.page === 1}>
              Previous
            </Button>
            <div className="page-jump-container d-flex align-items-center my-2">
              <input
                type="number"
                className="page-jump-input form-control me-2"
                placeholder={`On page ${paginationData.page} of ${Math.ceil(paginationData.total_records / 20)}`}
                value={jumpPage}
                disabled={Math.ceil(paginationData.total_records / 20) === 1}
                readOnly={Math.ceil(paginationData.total_records / 20) === 1}
                onChange={(e) => {
                  const value = e.target.value;
                  if (
                    value === '' ||
                    (/^[0-9]+$/.test(value) &&
                      value > 0 &&
                      value <= Math.ceil(paginationData.total_records / 20))
                  ) {
                    setJumpPage(value);
                  } else {
                    setJumpPage('');
                  }
                }}
              />
              <Button
                className={`blue-btn ${
                  Math.ceil(paginationData.total_records / 20) === 1 ||
                  jumpPage === '' ||
                  paginationData.page == jumpPage
                    ? 'disabled'
                    : ''
                } px-3`}
                clickHandler={() => {
                  handleJumpToPage();
                  setJumpPage('');
                }}
                disabled={
                  Math.ceil(paginationData.total_records / 20) === 1 ||
                  jumpPage === '' ||
                  paginationData.page == jumpPage
                }>
                Go
              </Button>
            </div>

            <Button
              className={`blue-btn d-flex align-items-center justify-content-center px-3 me-2 mx-1 ${
                paginationData.page === Math.ceil(paginationData.total_records / 20)
                  ? 'disabled'
                  : ''
              }`}
              clickHandler={() =>
                setPageNumber((prev) =>
                  Math.min(prev + 1, Math.ceil(paginationData.total_records / 20)),
                )
              }
              disabled={paginationData.page === Math.ceil(paginationData.total_records / 20)}>
              Next
            </Button>
          </div>
        </div>
      )}
      <div className="bg-white py-4 px-3 matrix-box">
        <div className="row custom-row">
          {Array.isArray(sortedData) && sortedData.length > 0 ? (
            sortedData.map((lot) => {
              return lot.ready_qty === 0 ? (
                <RedTile key={lot.id} lot={lot} />
              ) : lot.ready_qty < lot.total_qty ? (
                <YellowTile key={lot.id} lot={lot} />
              ) : (
                <GreenTile key={lot.id} lot={lot} />
              );
            })
          ) : (
            <div>
              <h4 className="px-1 text-center py-5">No Records Found</h4>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const getReadyQty = (lot) => {
  const qty = lot?.batch?.reduce((acc, cum) => {
    cum.state === DATA_FIELD.PACKED ? (acc = acc + cum.order_qty) : (acc = acc + 0);
    return acc;
  }, 0);
  return qty;
};
export const getBatchQty = (lot) => {
  const qty = reducedCalc(lot?.batch, DATA_FIELD.ORDER_QTY);
  return qty;
};
