import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SearchBar, TitleBar, DropDown, PaginatedTable } from '../../common';
import { RECEIPT_COLUMN, ROUTES, TITLES, BUTTON } from '../../../constants';
import { useDebounce } from '../../../hooks';
import { vendorReceipt } from '../../../service';
import { setLoaderVisibility } from '../../../redux';
import { receiptCompiler} from '../../../utils';
import { Button } from '../../shared';

export const ReceiptContent = () => {
  const [receiptData, setReceiptsData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(RECEIPT_COLUMN[7].name);
  const [sortingArray1, setSortingArray1] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();

  const fetchReceipts = useDebounce((params = {}) => {
    dispatch(setLoaderVisibility(true));
    vendorReceipt({ data: params }).then((response) => {
      dispatch(setLoaderVisibility(false));
      if (response?.data?.records?.length > 0) {
        setReceiptsData(response.data.records);
        setPaginationData(response.data.params);
        if (!sortingArray1.includes(sort1)) {
          let name = RECEIPT_COLUMN[7].dataField[0];
             setSortingArray1(response?.data[name]);
        }
      } else {
        setReceiptsData([]);
      }
    });
  }, 400);

  const getReceiptsList = (additionalParams = {}) => {
    dispatch(setLoaderVisibility(true));
    const params = {
      page_number: pageNumber,
      search: searchTerm
        ? {
            name: searchTerm,
            origin: searchTerm,
            lot_name: searchTerm,
            container_id: searchTerm,
          }
        : {},
      sort: sortingArray1.includes(sort1) ? { selection: { state: sort1.toLowerCase() } } : {},
      ...additionalParams,
    };
    fetchReceipts(params);
  };

  useEffect(() => {
    if (search) {
      setPageNumber(1); // Reset page number on new search
      // setSearch(false);
      getReceiptsList({ page_number: 1 });
    }
  }, [search]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getReceiptsList();
  }, [pageNumber]);

  useEffect(() => {
    if (sortingArray1.length > 0) {
      setPageNumber(1); // Reset page number on sorting change
      getReceiptsList({ page_number: 1 });
    }
  }, [sort1]);

  const resetFilter = () => {
    setSort1(RECEIPT_COLUMN[7].name);
    setPageNumber(1);
    getReceiptsList({ page_number: 1 });
  };

  useEffect(() => {
    if (!searchTerm && search) {
      setPageNumber(1);
      setSearch(false);
      getReceiptsList({ page_number: 1 });
    }
  }, [searchTerm]);

  return (
    <>
      <TitleBar title={[{ text: TITLES.RECEIPT, route: ROUTES.RECEIPT }]} />
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar
          search={search}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          setSearch={setSearch}
          onDefaultSearch={getReceiptsList}
        />
        <div className="w-50 mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1} />
          {sortingArray1.includes(sort1) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(receiptData) && receiptData?.length > 0 ? (
        <PaginatedTable
          columns={RECEIPT_COLUMN}
          initialData={receiptData}
          colFilter={false}
          to={ROUTES.RECEIPT}
          compiler={{ use: true, tool: receiptCompiler }}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          paginationData={paginationData}
        />
      ) : (
        <div>
          <h4 className="text-center px-1 py-5">No Records Found</h4>
        </div>
      )}
    </>
  );
};
