let monthNumberFromString = (str) => {
  return new Date(`${str} 01 2000`).toLocaleDateString(`en`, { month: `2-digit` });
};
export const dateFormatter = (date) => {
  const value = `${date}`;
  return `${value.substring(11, 15)}-${monthNumberFromString(value.substring(4, 7))}-${value.substring(8, 10)}`;
};

export const getCurrentDateInput = () => {
  const dateObj = new Date();
  // get the month in this format of 04, the same for months
  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
  const day = ('0' + dateObj.getDate()).slice(-2);
  const year = dateObj.getFullYear();
  const shortDate = `${day}-${month}-${year}`;
  return shortDate;
};

export const dateFormatChanger = (value) => {
  if (value) {
    let date = new Date(value);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const shortDate = `${day < 10 ? '0' + `${day}` : day}/${month < 10 ? '0' + `${month}` : month}/${year}`;
    return shortDate;
  }
};

export const showDate = (date) => {
  if (date) {
    return date;
  } else {
    const today = new Date().toISOString().slice(0, 10);
    return today;
  }
};
export const apiDateFormat = (value) => {
  if (value) {
    const date = `${value} 00:00:00`;
    return date;
  } else {
    return `${new Date().toISOString().slice(0, 10)}  00:00:00`;
  }
};

export const apiDateFormat2 = (value) => {
  let date = new Date(value);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const shortDate = `${year}-${month < 10 ? '0' + `${month}` : month}-${day < 10 ? '0' + `${day}` : day}`;
  return shortDate;
};

export const reducedCalc = (data = [], field = '') => {
  const qty = data.reduce((acc, cum) => {
    acc += cum[field];
    return acc;
  }, 0);
  return qty;
};

export function formatBillDate(inputDate) {
  if (!inputDate || inputDate === 'NaN') {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }
  const parts =
    typeof inputDate === 'string' && inputDate.includes('-') ? inputDate.split('-') : inputDate;
  if (parts.length === 3) {
    const [day, month, year] = parts;
    if (day.length === 4) return `${day}-${month}-${year}`;
    return `${year}-${month}-${day}`;
  }
  return new Date().toISOString().split('T')[0];
}

export const sortingByName = ({ sortBy, data, order }) => {
  data.sort((a, b) => {
    const nameA = a[sortBy]?.toLowerCase();
    const nameB = b[sortBy]?.toLowerCase();
    // Compare the names
    if (nameA < nameB) {
      return order ? 1 : -1;
    }
    if (nameA > nameB) {
      return order ? -1 : 1;
    }
    return 0;
  });
  return data;
};
export const sortingByValue = ({ sortBy, data, order }) => {
  const [sortBy1, sortBy2] = sortBy;
  if (sortBy.length > 1) {
    data.sort((a, b) => {
      const valueA = a[sortBy1] - a[sortBy2];
      const valueB = b[sortBy1] - b[sortBy2];
      return order ? valueB - valueA : valueA - valueB;
    });
    return data;
  } else {
    data.sort((a, b) => {
      const valueA = a[sortBy1];
      const valueB = b[sortBy1];
      return order ? valueB - valueA : valueA - valueB;
    });
    return data;
  }
};

export const getSortingArray = ({ column, data, compiler }) => {
  if (Array.isArray(data) && data.length) {
    let newData = compiler(data);
    let dataField = column.dataField[0];
    let valueArray = newData
      .map((obj) => {
        return obj[dataField];
      })
      .reduce((acc, val) => {
        if (typeof val === 'string' && val.length > 0) {
          let formattedVal = val[0]?.toUpperCase() + val.slice(1).replaceAll('_', ' ');
          if (!acc.includes(formattedVal)) {
            acc.push(formattedVal);
          }
        }
        return acc;
      }, [])
      .sort((a, b) => (a < b ? -1 : 1));
    return valueArray;
  }
};

export function capitalize(input) {
  if (typeof input !== 'string' || input.length === 0) {
    return '';
  }
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export const sortingByDate = ({ sortBy, data, order }) => {
  data.sort((a, b) => {
    const valueA = new Date(a[sortBy]).getTime();
    const valueB = new Date(b[sortBy]).getTime();
    return order ? valueA - valueB : valueB - valueA;
  });
  return data;
};
export const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .replaceAll('_', ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};