import React, { useState, useCallback, useEffect } from 'react';
import { TableHead, TableBody, Button } from '../shared';
import { popup, sortingByDate, sortingByName, sortingByValue } from '../../utils';
import { POPUP_TYPE, SORTING } from '../../constants';

export const PaginatedTable = ({
  columns,
  initialData = [],
  PAGE_SIZE = 20,
  colFilter,
  to,
  compiler,
  popUp,
  setPopUp,
  setPopUpData,
  setReload,
  func,
  className = 'table-1',
  setPageNumber,
  paginationData,
}) => {
  const [selectedColumns, setSelectedColumns] = useState(columns.map((col) => col.name));
  const [sortInfo, setSortInfo] = useState({
    sortType: SORTING.DEFAULT,
    index: 0,
    valueType: '',
    value: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [jumpPage, setJumpPage] = useState(''); // State for input box

  const finalColumns = columns.filter((column) => selectedColumns.includes(column.name));

  // Initialize data on component mount or when initialData changes
  useEffect(() => {
    setData(Array.isArray(initialData) ? initialData : []);
    setCurrentPage(1); // Reset to the first page on data change
  }, [initialData]);

  // Update paginated data when data or currentPage changes
  useEffect(() => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    setPaginatedData(data.slice(startIndex, endIndex));
  }, [data, currentPage, PAGE_SIZE]);

  // Sorting handler function
  const handleSort = useCallback(
    ({ sortInfo, data }) => {
      const { sortType, index, valueType } = sortInfo;
      let result = [];
      const dataFieldName = columns[index]?.dataField;

      if (valueType === 'String') {
        result = sortingByName({
          data: data,
          sortBy: dataFieldName,
          order: sortType === SORTING.ASCENDING ? 1 : 0,
        });
      } else if (valueType === 'Number') {
        result = sortingByValue({
          data: data,
          sortBy: dataFieldName,
          order: sortType === SORTING.ASCENDING ? 0 : 1,
        });
      } else {
        result = sortingByDate({
          data: data,
          sortBy: dataFieldName,
          order: sortType === SORTING.ASCENDING ? 0 : 1,
        });
      }
      return result;
    },
    [columns],
  );

  // Pagination logic for displayed page numbers
  // const getDisplayedPages = () => {
  //   const totalPages = Math.ceil(paginationData.total_records / PAGE_SIZE);
  //   const currentPage = paginationData.page;
  //   const pages = [];

  //   if (currentPage > 1) pages.push(currentPage - 1);
  //   pages.push(currentPage);
  //   if (currentPage < totalPages) pages.push(currentPage + 1);

  //   // Ensure two pages ahead for the first page
  //   if (currentPage === 1) {
  //     if (currentPage + 2 <= totalPages) pages.push(currentPage + 2);
  //   }

  //   // Ensure two pages behind for the last page
  //   if (currentPage === totalPages) {
  //     if (currentPage - 2 > 0) pages.unshift(currentPage - 2);
  //   }

  //   return pages;
  // };

  // Handle page jump input
  const handleJumpToPage = () => {
    const totalPages = Math.ceil(paginationData.total_records / PAGE_SIZE);
    const targetPage = parseInt(jumpPage, 10);
    if (targetPage >= 1 && targetPage <= totalPages) {
      setPageNumber(targetPage);
    } else {
      popup(POPUP_TYPE.ERR, `Please enter a valid page number between 1 and ${totalPages}.`);
    }
  };

  return (
    <>
      {Array.isArray(paginatedData) && paginatedData.length > 0 ? (
        <>
          <div className="pagination-container d-flex justify-content-between align-items-center mb-2">
            <h5 className="pagination-info">
              <span className="highlight">Records:</span>
              <span className="record-range">
                {paginationData.start} - {paginationData.end}
              </span>
              <span className="highlight">of</span>
              <span className="total-records">{paginationData.total_records}</span>
            </h5>

            <div className="pagination-controls d-flex align-items-center justify-content-end">
              <Button
                className={`blue-btn d-flex align-items-center justify-content-center px-3 me-2 ${
                  paginationData.page === 1 ? 'disabled' : ''
                }`}
                clickHandler={() => setPageNumber((prev) => Math.max(prev - 1, 1))}
                disabled={paginationData.page === 1}>
                Previous
              </Button>
              {/* {getDisplayedPages().map((page) => (
            <Button
              key={page}
              className={`d-flex align-items-center justify-content-center px-3 me-1 ${
                paginationData.page === page ? 'blue-btn' : 'white-btn'
              }`}
              clickHandler={() => setPageNumber(page)}>
              {page}
            </Button>
          ))} */}
              <div className="page-jump-container d-flex align-items-center my-2">
                <input
                  type="number"
                  className="page-jump-input form-control me-2"
                  placeholder={`On page ${paginationData.page} of ${Math.ceil(paginationData.total_records / PAGE_SIZE)}`}
                  value={jumpPage}
                  disabled={Math.ceil(paginationData.total_records / PAGE_SIZE) === 1}
                  readOnly={Math.ceil(paginationData.total_records / PAGE_SIZE) === 1}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (
                      value === '' ||
                      (/^[0-9]+$/.test(value) &&
                        value > 0 &&
                        value <= Math.ceil(paginationData.total_records / PAGE_SIZE))
                    ) {
                      setJumpPage(value);
                    } else {
                      setJumpPage('');
                    }
                  }}
                />
                <Button
                  className={`blue-btn ${
                    Math.ceil(paginationData.total_records / PAGE_SIZE) === 1 ||
                    jumpPage === '' ||
                    paginationData.page == jumpPage
                      ? 'disabled'
                      : ''
                  } px-3`}
                  clickHandler={() => {
                    handleJumpToPage();
                    setJumpPage('');
                  }}
                  disabled={
                    Math.ceil(paginationData.total_records / PAGE_SIZE) === 1 ||
                    jumpPage === '' ||
                    paginationData.page == jumpPage
                  }>
                  Go
                </Button>
              </div>

              <Button
                className={`blue-btn d-flex align-items-center justify-content-center px-3 me-2 mx-1 ${
                  paginationData.page === Math.ceil(paginationData.total_records / PAGE_SIZE)
                    ? 'disabled'
                    : ''
                }`}
                clickHandler={() =>
                  setPageNumber((prev) =>
                    Math.min(prev + 1, Math.ceil(paginationData.total_records / PAGE_SIZE)),
                  )
                }
                disabled={
                  paginationData.page === Math.ceil(paginationData.total_records / PAGE_SIZE)
                }>
                Next
              </Button>
            </div>
          </div>
          {/* <div className="page-jump-container d-flex align-items-center my-2">
        <input
          type="number"
          className="page-jump-input form-control me-2"
          placeholder="Jump to page"
          value={jumpPage}
          onChange={(e) => setJumpPage(e.target.value)}
        />
        <Button
          className="blue-btn px-3"
          clickHandler={handleJumpToPage}>
          Go
        </Button>
      </div> */}
          <div className={`table-container ${className} position-relative mb-3`}>
            <table className="w-100">
              <TableHead
                columns={columns}
                showColumn={finalColumns}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                colFilter={colFilter}
                sortInfo={sortInfo}
                setSortInfo={setSortInfo}
              />
              <TableBody
                columns={finalColumns}
                data={paginatedData}
                colFilter={colFilter}
                to={to !== '' ? to : undefined}
                compiler={compiler}
                sortInfo={sortInfo}
                popUp={popUp}
                setPopUp={setPopUp}
                setPopUpData={setPopUpData}
                setReload={setReload}
                handleSort={handleSort}
                func={func}
              />
            </table>
          </div>
        </>
      ) : (
        <div>
          <h4 className="text-center px-1 py-5">No Records Found</h4>
        </div>
      )}
    </>
  );
};
