import React from 'react';
import { DropDownArrow2 } from '../../assets/icons';
import { capitalizeWords } from '../../utils';

export const DropDown = ({ dropdownMenu, value, setValue, className = '' }) => {
  const handleDropdownMenu = (item) => {
    setValue(item);
  };

  return (
    <div
      className={`${className} custom-dropdown-3 d-flex align-items-center justify-content-center`}>
      <div className="dropdown">
        <button
          className="btn dropdown-toggle bg-white d-flex align-items-center justify-content-between"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          {capitalizeWords(value)}
          <DropDownArrow2 />
        </button>
        <ul className="dropdown-menu bg-white border-0 py-0" aria-labelledby="dropdownMenuButton1">
          {dropdownMenu.map((item, index) => (
            <li
              key={index}
              onClick={() => handleDropdownMenu(item)}
              className={`${item === value ? 'bg-dark-subtle ' : ''}`}>
              <div className="dropdown-item d-flex align-items-center justify-content-start">
                {capitalizeWords(item)}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
