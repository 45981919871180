import { capitalize } from '../misc';

export function receiptCompiler(data) {
  let finalData = [];
  data?.map((receipt) => {
    let receiptData = {
      url: receipt?.name?.replaceAll('/', '_'),
      id: receipt?.id,
      reference: receipt?.name,
      from: receipt?.location_id?.display_name,
      to: receipt?.location_dest_id?.display_name,
      container: receipt?.container_id || "N/A",
      contact: receipt?.vendor_id ? receipt?.vendor_id?.name : receipt?.partner_id?.name || 'N/A',
      state: capitalize(receipt.state),
      lot: receipt?.lot_name || "N/A",
      source_document: receipt?.origin || '',
      schedule_date: receipt?.scheduled_date,
      deadline: receipt?.date_deadline,
      data: receipt,
    };
    finalData.push(receiptData);
  });
  return finalData;
}

export function receiptDetailCompiler({ moveIds, state }) {
  let finalData = [];
  moveIds?.map((move) => {
    let receiptDetailData = {
      id: move?.id,
      rolls: move?.no_of_rolls,
      product: move?.product_id?.display_name,
      lotNo:
        move?.product_id?.tracking === 'lot'
          ? move?.lot_ids?.length > 0
            ? move?.lot_ids?.[0]?.name
            : move?.move_line_ids?.[0]?.lot_name
          : 'N/A',
      demand_quantity: move?.product_uom_qty,
      received_quantity: move?.quantity,
      unit: move?.product_uom?.name,
      tracking: move?.product_id?.tracking,
      lot_ids: move?.lot_ids,
      move_line_ids: move?.move_line_ids,
      state: capitalize(state),
      showEdit: move?.subcontract_has_been_recorded,
    };
    finalData.push(receiptDetailData);
  });
  return finalData;
}

export function receiptDetailsBox(receipt) {
  let finalData = [
    { name: 'Destination Location', col: 1, data: receipt?.location_dest_id?.display_name },
    { name: 'Receive From', col: 1, data: receipt?.location_id?.display_name },
    { name: 'Container No', col: 1, data: receipt?.container_id || 'N/A' },
    { name: 'Scheduled Date', col: 2, data: receipt?.scheduled_date },
    { name: 'Deadline', col: 2, data: receipt?.date_deadline || 'N/A' },
    { name: 'Source', col: 2, data: receipt?.origin || 'N/A' },
  ];
  return finalData;
}
