import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../../redux';
import { SearchBar, TitleBar, DropDown, PaginatedTable } from '../../../common';
import { PRODUCTION_COLUMN, ROUTES, TITLES, BUTTON, LOCAL_STORAGE } from '../../../../constants';
import { Button } from '../../../shared';
import { vendorManufacturing } from '../../../../service';
import { useDebounce } from '../../../../hooks';
import { dispatchDataCompiler, getLocalStorageItem } from '../../../../utils';
export const ProductionContent = () => {
  const dispatch = useDispatch();
  const [ProductionData, setProductionData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(PRODUCTION_COLUMN[7].name);
  const [sortingArray1, setSortingArray1] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  const column = PRODUCTION_COLUMN.filter((col) => col.name !== 'Priority');

  const fetchProductionData = useDebounce((params = {}) => {
    dispatch(setLoaderVisibility(true));
    vendorManufacturing({ data: params }).then((response) => {
      dispatch(setLoaderVisibility(false));
      if (response?.data?.records?.length > 0) {
        setProductionData(response.data.records);
        setPaginationData(response.data.params);
        if (!sortingArray1.includes(sort1)) {
          let name = PRODUCTION_COLUMN[7].dataField[0];
          setSortingArray1(response?.data[name]);
        }
      } else {
        setProductionData([]);
      }
    });
  }, 400);

  const getProductionList = (additionalParams = {}) => {
    dispatch(setLoaderVisibility(true));
    const params = {
      page_number: pageNumber,
      search: searchTerm
        ? {
            name: searchTerm,
            origin: searchTerm,
            lot_name: searchTerm,
          }
        : {},
      sort: sortingArray1.includes(sort1) ? { selection: { state: sort1.toLowerCase() } } : {},
      ...additionalParams,
    };
    fetchProductionData(params);
  };

  useEffect(() => {
    if (search) {
      setPageNumber(1); // Reset page number on new search
      // setSearch(false);
      getProductionList({ page_number: 1 });
    }
  }, [search]);

  useEffect(() => {
    getProductionList();
  }, [pageNumber]);

  useEffect(() => {
    if (sortingArray1.length > 0) {
      setPageNumber(1); // Reset page number on sorting change
      getProductionList({ page_number: 1 });
    }
  }, [sort1]);

  const resetFilter = () => {
    setSort1(PRODUCTION_COLUMN[7].name);
    setPageNumber(1);
    getProductionList({ page_number: 1 });
  };

  useEffect(() => {
    if (!searchTerm && search) {
      setPageNumber(1);
      setSearch(false);
      getProductionList({ page_number: 1 });
    }
  }, [searchTerm]);
  return (
    <>
      <TitleBar title={[{ text: TITLES.MANUFACTURING, route: ROUTES.MANUFACTURING }]} />
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar
          search={search}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          setSearch={setSearch}
          onDefaultSearch={getProductionList}
        />
        <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1}></DropDown>
          {sortingArray1.includes(sort1) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(ProductionData) && ProductionData?.length > 0 ? (
        <PaginatedTable
          columns={
            getLocalStorageItem(LOCAL_STORAGE.VENDOR_TYPE) === 'fabric' ? PRODUCTION_COLUMN : column
          }
          initialData={ProductionData}
          colFilter={true}
          to={ROUTES.MANUFACTURING}
          compiler={{ use: true, tool: dispatchDataCompiler }}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          paginationData={paginationData}
        />
      ) : (
        <div>
          <h4 className="text-center px-1 py-5">No records found</h4>
        </div>
      )}
    </>
  );
};
