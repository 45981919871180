import React, { useEffect, useState } from 'react';
import { DetailBox, PaginatedTable, TitleBar } from '../../../common';
import {
  TITLES,
  ROUTES,
  FABRIC_PRODUCTION_DETAILS_COLUMN,
  BUTTON,
  LOCAL_STORAGE,
} from '../../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { FabricProductionModal } from './FabricProduction';
import { Button, PopUpBox } from '../../../shared';
import { dispatchDetailsBox } from '../../../../utils/dataCompiler/dispatchDataCompiler';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../../hooks';
import { validateDispatch, vendorReceipt } from '../../../../service/receipt';
import { setLoaderVisibility } from '../../../../redux';
import { fabricManufacturingProductCompiler, getLocalStorageItem, popup } from '../../../../utils';

export const ProductionDetails = () => {
  const { state } = useLocation(location?.state);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productData, setProductData] = useState([]);
  const [popUpData, setPopUpData] = useState({});
  const [reload, setReload] = useState(false);
  const [column, setColumn] = useState(FABRIC_PRODUCTION_DETAILS_COLUMN);
  const dispatch = useDispatch();
  const [paginationData, setPaginationData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  let enableValidate = () => {
    return productData.length
      ? productData.every((move) => move.lotno && move.producing_quantity > 0)
      : false;
  };

  const handleValidate = useDebounce(() => {
    // API Call to load data
    dispatch(setLoaderVisibility(true));
    validateDispatch({
      data: { picking_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.MANUFACTURING);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  }, 400);

  const getProductsList = useDebounce(() => {
    // API Call to load data
    setReload(false);
    vendorReceipt({
      data: { receipt_ids: state?.id, page_number: pageNumber },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.data?.records?.length > 0) {
          setProductData(fabricManufacturingProductCompiler(response.data.records[0]));
          setPaginationData(response?.data?.params);
        } else {
          setProductData([]);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  }, 400);
  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getProductsList();
  }, [reload]);

  useEffect(() => {
    if (!state) navigate(ROUTES.MANUFACTURING);
    if (
      productData?.[0]?.state === 'done' ||
      state?.state === 'done'
      // ||(productData.length && productData.every((prod) => !prod.material))
    )
      setColumn(column.filter((col) => col.name !== 'Action'));
    if (getLocalStorageItem(LOCAL_STORAGE.VENDOR_TYPE) !== 'fabric')
      setColumn(column.filter((col) => col.name !== 'Rolls'));
  }, [productData]);

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.MANUFACTURING, route: ROUTES.MANUFACTURING },
          { text: state?.name, route: ROUTES.NULL },
        ]}
      />
      <div className="scroll-div">
        {(state?.state === 'draft' || state?.state === 'assigned') && (
          <div className="d-flex justify-content-end gap-2 mb-3">
            <Button
              disabled={!enableValidate()}
              clickHandler={handleValidate}
              className={`border-0 blue-btn ${enableValidate() ? '' : 'disabled'} d-flex align-items-center justify-content-center py-2 px-3`}>
              {BUTTON.VALIDATE}
            </Button>
          </div>
        )}
        <DetailBox colCount={2} colData={state} dataCompiler={dispatchDetailsBox} />
        <PaginatedTable
          columns={column}
          initialData={productData}
          colFilter={false}
          popUp={isModalOpen}
          setPopUp={setIsModalOpen}
          setPopUpData={setPopUpData}
          paginationData={paginationData}
          setPageNumber={setPageNumber}
        />
      </div>
      {isModalOpen && (
        <PopUpBox openPopUpBox={isModalOpen}>
          <FabricProductionModal
            closePopUp={setIsModalOpen}
            modalData={popUpData}
            setReload={setReload}
          />
        </PopUpBox>
      )}
    </>
  );
};
