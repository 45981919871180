import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SearchBar, TitleBar, DropDown, PaginatedTable } from '../../common';
import {
  BUTTON,
  DISPATCH_COLUMN,
  ROUTES,
  TITLES,
  POPUP_TYPE,
  LOCAL_STORAGE,
} from '../../../constants';
import { useDebounce } from '../../../hooks';
import { vendorCreateDispatch, vendorDispatch } from '../../../service';
import { setLoaderVisibility } from '../../../redux';
import { dispatchDataCompiler, getLocalStorageItem, popup, } from '../../../utils';
import { Button } from '../../shared';

export const DispatchContent = () => {
  const [dispatchData, setDispatchData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(DISPATCH_COLUMN[8].name);
  const [sort2, setSort2] = useState(DISPATCH_COLUMN[9].name);
  const [sortingArray1, setSortingArray1] = useState([]);
  const [sortingArray2, setSortingArray2] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const createDispatch = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchDispatchList = useDebounce((params = {}) => {
    dispatch(setLoaderVisibility(true));
    vendorDispatch({ data: params })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.data?.records?.length > 0) {
          setDispatchData(response.data.records);
          setPaginationData(response.data.params);

          if (!sortingArray1.includes(sort1)) {
            let name = DISPATCH_COLUMN[8].dataField[0];
            setSortingArray1(response?.data[name]);
          }

          if (!sortingArray2.includes(sort2)) {
            let name = DISPATCH_COLUMN[9].dataField[0];
            setSortingArray2(response?.data[name]);
          }
        } else {
          setDispatchData([]);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const getCreateDispatch = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    vendorCreateDispatch({ data: {} })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.data?.records?.length > 0) {
          createDispatch[1](response.data.records[0]);
          setPaginationData(response.data.params);
          navigate(ROUTES.CREATE_DISPATCH, { state: response.data.records[0] });
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const handleCreateDispatch = () => {
    dispatch(setLoaderVisibility(true));
    getCreateDispatch();
  };

  const getDispatchList = (additionalParams = {}) => {
    const params = {
      page_number: pageNumber,
      search: searchTerm
        ? {
            name: searchTerm,
            origin: searchTerm,
            lot_name: searchTerm,
            container_id: searchTerm,
          }
        : {},
      sort: {
        selection: {
          ...(sortingArray1.includes(sort1) ? { dispatch_status: sort1.toLowerCase() } : {}),
          ...(sortingArray2.includes(sort2) ? { state: sort2.toLowerCase() } : {}),
        },
      },

      ...additionalParams,
    };
    fetchDispatchList(params);
  };

  useEffect(() => {
    if (search) {
      setPageNumber(1);
      // setSearch(false);
      getDispatchList({ page_number: 1 });
    }
  }, [search]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getDispatchList();
  }, [pageNumber]);

  useEffect(() => {
    if (sortingArray1.length > 0 || sortingArray2.length > 0) {
      setPageNumber(1);
      getDispatchList({ page_number: 1 });
    }
  }, [sort1, sort2]);

  const resetFilter = () => {
    setSort1(DISPATCH_COLUMN[8].name);
    setSort2(DISPATCH_COLUMN[9].name);
    setPageNumber(1);
    getDispatchList({ page_number: 1 });
  };

  useEffect(() => {
    if (!searchTerm && search) {
      setPageNumber(1);
      setSearch(false);
      getDispatchList({ page_number: 1 });
    }
  }, [searchTerm]);

  const tags = getLocalStorageItem('tags');
  let destinationRoute;
  if (tags[0] === 'clothing' && dispatchData?.[0]?.partner_id?.vendor_type !== 'dyeing') {
    destinationRoute = ROUTES.DISPATCH_DETAILS;
  } else if (tags[0] === 'fabric' || dispatchData?.[0]?.partner_id?.vendor_type === 'dyeing') {
    destinationRoute = ROUTES.FABRIC_DISPATCH_DETAIL;
  } else {
    destinationRoute = ROUTES.YARN_DISPATCH_DETAIL;
  }

  return (
    <>
      <TitleBar title={[{ text: TITLES.DISPATCH, route: ROUTES.DISPATCH }]} />
      <div className="d-flex justify-content-end my-3">
        {getLocalStorageItem('tags')[0] === 'clothing' && (
          <Button
            clickHandler={handleCreateDispatch}
            className="white-btn d-flex align-items-center justify-content-center px-4 mx-2 mt-1">
            {BUTTON.CREATE_DISPATCH}
          </Button>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar
          search={search}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          setSearch={setSearch}
          onDefaultSearch={getDispatchList}
        />
        <div className="w-50 mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1} />
          <DropDown dropdownMenu={sortingArray2} value={sort2} setValue={setSort2} />
          {(sortingArray1.includes(sort1) || sortingArray2.includes(sort2)) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(dispatchData) && dispatchData.length > 0 ? (
        <PaginatedTable
          columns={DISPATCH_COLUMN}
          initialData={dispatchData}
          colFilter={true}
          to={destinationRoute}
          compiler={{ use: true, tool: dispatchDataCompiler }}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          paginationData={paginationData}
          className={
            getLocalStorageItem(LOCAL_STORAGE.VENDOR_TYPE) === 'clothing' ? 'table-4' : 'table-1'
          }
        />
      ) : (
        <div>
          <h4 className="text-center px-1 py-5">No Records Found</h4>
        </div>
      )}
    </>
  );
};
