import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { SearchBar, TitleBar, DropDown, PaginatedTable } from '../../common';
import { BILLS_COLUMN, ROUTES, TITLES, POPUP_TYPE, BUTTON } from '../../../constants';
import { Button } from '../../shared';
import { vendorBill } from '../../../service';
import { useDebounce } from '../../../hooks';
import { billDataCompiler, popup } from '../../../utils';

export const BillsContent = () => {
  const dispatch = useDispatch();
  const [billsData, setBillsData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(BILLS_COLUMN[7].name);
  const [sortingArray1, setSortingArray1] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const fetchBills = useDebounce((params = {}) => {
    dispatch(setLoaderVisibility(true));
    vendorBill({ data: params })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.data?.records?.length > 0) {
          setBillsData(response.data.records);
          setPaginationData(response.data.params);
          if (!sortingArray1.includes(sort1)) {
            let name = BILLS_COLUMN[7].dataField[0];
            setSortingArray1(response?.data[name]);
          }
        } else {
          setBillsData([]);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const getBillsList = (additionalParams = {}) => {
    dispatch(setLoaderVisibility(true));
    const params = {
      page_number: pageNumber,
      search: searchTerm
        ? {
            name: searchTerm,
            invoice_origin: searchTerm,
            'invoice_line_ids.future_lot': searchTerm,
          }
        : {},
      sort: sortingArray1.includes(sort1) ? { selection: { state: sort1.toLowerCase() } } : {},
      ...additionalParams,
    };
    fetchBills(params);
  };

  useEffect(() => {
    if (search) {
      setPageNumber(1); // Reset page number on search
      // setSearch(false);
      getBillsList({ page_number: 1 });
    }
  }, [search]);

  useEffect(() => {
    getBillsList();
  }, [pageNumber]);

  useEffect(() => {
    if (sortingArray1.length > 0) {
      setPageNumber(1); // Reset page number on sorting change
      getBillsList({ page_number: 1 });
    }
  }, [sort1]);

  const resetFilter = () => {
    setSort1(BILLS_COLUMN[7].name);
    setPageNumber(1);
    getBillsList({ page_number: 1 });
  };

  useEffect(() => {
    if (!searchTerm && search) {
      setPageNumber(1);
      setSearch(false);
      getBillsList({ page_number: 1 });
    }
  }, [searchTerm]);

  return (
    <>
      <TitleBar title={[{ text: TITLES.BILLS, route: ROUTES.BILLS }]} />
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar
          search={search}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          setSearch={setSearch}
          onDefaultSearch={getBillsList}
        />
        <div className="w-50 mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1} />
          {sortingArray1.includes(sort1) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(billsData) && billsData?.length > 0 ? (
        <PaginatedTable
          columns={BILLS_COLUMN}
          initialData={billsData}
          colFilter={false}
          to={ROUTES.BILLS}
          compiler={{ use: true, tool: billDataCompiler }}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          paginationData={paginationData}
        />
      ) : (
        <div>
          <h4 className="text-center px-1 py-5">No Records Found</h4>
        </div>
      )}
    </>
  );
};
