import React, { useEffect, useState } from 'react';
import { SearchBar, TitleBar, BlpTiles, DropDown } from '../../common';
import {
  BUTTON,
  HEADING,
  PKJ_STATUS,
  POPUP_MSG,
  POPUP_TYPE,
  ROUTES,
  TITLES,
} from '../../../constants';
import { Button } from '../../shared';
import { useNavigate } from 'react-router-dom';
import { StatusBox } from '../manufacturing/clothManufacturing/StatusBox';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { vendorPackage, printLabelPackage } from '../../../service/package';
import { useDebounce } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { packageDataCompiler } from '../../../utils/dataCompiler/packageDataCompiler';
import PrintLabelModalBulk from './PrintLabelModalBulk';

export const PackageDetail = () => {
  const navigate = useNavigate();
  const [packageData, setPackageData] = useState([]);
  const [sortingArray1, setSortingArray1] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState('Box Type');
  const [printMode, setPrintMode] = useState(false);
  const [selectedBlp, setSelectedBlp] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [jumpPage, setJumpPage] = useState('');
  const dispatch = useDispatch();

  const onTileClick = (to, data) => {
    navigate(to, { state: data });
  };

  const getPackageList = useDebounce((params = {}) => {
    dispatch(setLoaderVisibility(true));
    vendorPackage({ data: { page_number: pageNumber, ...params } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        const records = response?.data?.records || [];
        if (records.length > 0) {
          const compiledData = packageDataCompiler(records);
          setPackageData(compiledData);
          setPaginationData(response.data.params);
          if (!sortingArray1.includes(sort1)) {
            setSortingArray1(['Single Box', 'Mix Box']);
          }
        } else {
          setPackageData([]);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  const getList = (additionalParams = {}) => {
    const params = {
      page_number: pageNumber,
      search: searchTerm
        ? {
            name: searchTerm,
            'quant_ids.lot_id.name': searchTerm,
            'quant_ids.product_id.default_code': searchTerm,
          }
        : {},
      sort:
        sort1 == 'Box Type'
          ? {}
          : { selection: { is_mixed: sort1 === 'Single Box' ? false : true } },
      ...additionalParams,
    };
    getPackageList(params);
  };

  useEffect(() => {
    if (search) {
      setPageNumber(1);
      // setSearch(false);
      getList({ page_number: 1 });
    }
  }, [search]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getList();
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
    getList({ page_number: 1 });
  }, [sort1]);

  useEffect(() => {
    if (!searchTerm && search) {
      setPageNumber(1);
      setSearch(false);
      getList({ page_number: 1 });
    }
  }, [searchTerm]);

  const resetFilter = () => {
    setSort1('Box Type');
  };

  const bulkPrint = () => {
    if (printMode) {
      selectedBlp.length > 100
        ? popup(POPUP_TYPE.ERR, POPUP_MSG.PRINT_LIMIT)
        : selectedBlp.length > 0
          ? setIsModalOpen(true)
          : popup(POPUP_TYPE.ERR, 'No Package Selected');
    } else {
      setPrintMode(true);
      popup(POPUP_TYPE.SUCCESS, POPUP_MSG.SELECT_PACKAGE);
    }
  };

  const cancelPrint = () => {
    setPrintMode(false), setSelectedBlp([]);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleSubmitModal = (payload) => {
    dispatch(setLoaderVisibility(true));
    // Make an API call or perform other actions with the payload
    printLabelPackage({
      data: payload,
    })
      .then((response) => {
        if (response.data.length > 0) {
          dispatch(setLoaderVisibility(false));
          const url = response.data[0].split('?')[0];
          window.open(url, '_blank', 'noreferrer');
          setPrintMode(false);
          setSelectedBlp([]);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };
  const selectAll = () => {
    if (selectedBlp.length && selectedBlp.length === packageData.length) {
      setSelectedBlp([]);
    } else {
      setSelectedBlp(
        packageData.map((pack) => {
          return { id: pack.id };
        }),
      );
    }
  };
  const handleJumpToPage = () => {
    const totalPages = Math.ceil(paginationData.total_records / 20);
    const targetPage = parseInt(jumpPage, 10);

    if (targetPage >= 1 && targetPage <= totalPages) {
      setPageNumber(targetPage);
    } else {
      popup(POPUP_TYPE.ERR, `Please enter a valid page number between 1 and ${totalPages}.`);
    }
  };
  return (
    <>
      <TitleBar title={[{ text: TITLES.PACKAGE, route: ROUTES.PACKAGE }]} />
      <div className="bg-white p-3 package-top-info border-def-up border-def-down">
        <StatusBox status={PKJ_STATUS} />
        <div className="d-flex align-items-center justify-content-end mb-3 top-header-set">
          <div className="d-flex align-items-center">
            <h4 className="me-3 mb-0">{HEADING.BOX_COUNT}</h4>
            <div className="number-count d-flex align-items-center justify-content-center">
              {Array.isArray(packageData) && packageData.length ? packageData?.length : 0}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-start gap-3 w-100">
          <SearchBar
            search={search}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            setSearch={setSearch}
            onDefaultSearch={getList}
          />
          <div className="w-50 mb-3 filter-btns-div d-flex align-items-center justify-content-start">
            <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1} />
            {sortingArray1.includes(sort1) && (
              <Button
                clickHandler={resetFilter}
                className="blue-btn d-flex align-items-center justify-content-center px-3">
                {BUTTON.CLEAR_FILTERS}
              </Button>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between gap-2">
          <div className="d-flex justify-content-start align-items-center gap-2">
            {printMode && (
              <>
                <input
                  onChange={selectAll}
                  checked={selectedBlp.length > 1 && selectedBlp.length === packageData.length}
                  type="checkbox"></input>
                <div className="small-div">Select all</div>
              </>
            )}
          </div>
          {Array.isArray(packageData) && packageData?.length > 0 && (
            <div className="d-flex justify-content-end gap-2">
              <Button
                clickHandler={bulkPrint}
                className="white-btn d-flex align-items-center justify-content-center px-4 ">
                {BUTTON.PRINT_LABEL}
              </Button>
              {printMode && (
                <Button
                  clickHandler={cancelPrint}
                  className="white-btn d-flex align-items-center justify-content-center px-4 ">
                  {BUTTON.CANCEL}
                </Button>
              )}
              {!printMode && (
                <Button
                  clickHandler={() => navigate(ROUTES.ADD_MIX_BOX, { state: packageData })}
                  className="white-btn d-flex align-items-center justify-content-center ms-2 px-3 ">
                  {BUTTON.ADD_BOX}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      {Array.isArray(packageData) && packageData.length > 0 && (
        <div className="pagination-container d-flex justify-content-between align-items-center mb-2">
          <h5 className="pagination-info">
            <span className="highlight">Records:</span>
            <span className="record-range">
              {paginationData.start} - {paginationData.end}
            </span>
            <span className="highlight">of</span>
            <span className="total-records">{paginationData.total_records}</span>
          </h5>

          <div className="pagination-controls d-flex align-items-center justify-content-end">
            <Button
              className={`blue-btn d-flex align-items-center justify-content-center px-3 me-2 ${
                paginationData.page === 1 ? 'disabled' : ''
              }`}
              clickHandler={() => setPageNumber((prev) => Math.max(prev - 1, 1))}
              disabled={paginationData.page === 1}>
              Previous
            </Button>
            <div className="page-jump-container d-flex align-items-center my-2">
              <input
                type="number"
                className="page-jump-input form-control me-2"
                placeholder={`On page ${paginationData.page} of ${Math.ceil(paginationData.total_records / 20)}`}
                value={jumpPage}
                disabled={Math.ceil(paginationData.total_records / 20) === 1}
                readOnly={Math.ceil(paginationData.total_records / 20) === 1}
                onChange={(e) => {
                  const value = e.target.value;
                  if (
                    value === '' ||
                    (/^[0-9]+$/.test(value) &&
                      value > 0 &&
                      value <= Math.ceil(paginationData.total_records / 20))
                  ) {
                    setJumpPage(value);
                  } else {
                    setJumpPage('');
                  }
                }}
              />
              <Button
                className={`blue-btn ${
                  Math.ceil(paginationData.total_records / 20) === 1 ||
                  jumpPage === '' ||
                  paginationData.page == jumpPage
                    ? 'disabled'
                    : ''
                } px-3`}
                clickHandler={() => {
                  handleJumpToPage();
                  setJumpPage('');
                }}
                disabled={
                  Math.ceil(paginationData.total_records / 20) === 1 ||
                  jumpPage === '' ||
                  paginationData.page == jumpPage
                }>
                Go
              </Button>
            </div>

            <Button
              className={`blue-btn d-flex align-items-center justify-content-center px-3 me-2 mx-1 ${
                paginationData.page === Math.ceil(paginationData.total_records / 20)
                  ? 'disabled'
                  : ''
              }`}
              clickHandler={() =>
                setPageNumber((prev) =>
                  Math.min(prev + 1, Math.ceil(paginationData.total_records / 20)),
                )
              }
              disabled={paginationData.page === Math.ceil(paginationData.total_records / 20)}>
              Next
            </Button>
          </div>
        </div>
      )}
      <div className="bg-white p-3 pt-0 package-titles-info">
        <div className="row custom-row">
          {Array.isArray(packageData) && packageData.length > 0 ? (
            packageData.map((tile, index) => (
              <BlpTiles
                key={index}
                tile={tile}
                clickHandler={printMode ? bulkPrint : onTileClick}
                to={ROUTES.PACKAGE_BLP_OVERVIEW}
                printMode={printMode}
                selectedBlp={selectedBlp}
                setSelectedBlp={setSelectedBlp}
              />
            ))
          ) : (
            <div>
              <h5 className="px-1 text-center py-5">No Records Found</h5>
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <PrintLabelModalBulk
          onClose={handleCloseModal}
          onSubmit={handleSubmitModal}
          packageData={selectedBlp}
        />
      )}
    </>
  );
};
