import React from 'react';

export const DetailBox = ({
  colCount,
  colData,
  dataCompiler,
  value,
  setValue,
  clickHandler,
  showSave,
}) => {
  const data = dataCompiler(colData);
  return data ? (
    <>
      <div className="data-content">
        <div className="row">
          {Array.from({ length: colCount }).map((a, index) => (
            <div
              key={index}
              className={`${colCount === 1 ? 'col-xl-12 col-lg-12 col-12' : 'col-xl-6 col-lg-6 col-6'} mb-3 `}>
              <div className="bg-white data-box w-100 h-100">
                {data.map((column) =>
                  column.col === 1 + index ? (
                    column.isCustom ? (
                      column.view({ value, setValue, clickHandler, showSave })
                    ) : (
                      <div key={column.name} className="row mb-3">
                        <div className="col-6 title d-flex flex-wrap t-wrap">{column.name}</div>
                        <div className="col-6 d-flex flex-wrap t-wrap">
                          {column?.data && typeof column?.data === 'string'
                            ? column?.data?.replaceAll('_', ' ')
                            : column?.data}
                        </div>
                      </div>
                    )
                  ) : (
                    ''
                  ),
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
