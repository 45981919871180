import React, { useState, useEffect } from 'react';

export const Offline = () => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    // Event handlers for online and offline status
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    // Add event listeners
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      {isOffline && (
        <div className="offline d-flex justify-content-center align-items-center">
          <div className="offlineBanner">
            <div className="offline-message text-info">
              You are currently offline.
              Some features may not work.
            </div>
          </div>  
        </div>
      )}
    </>
  );
};  
