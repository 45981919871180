import { LOCAL_STORAGE } from '../../constants';
import { getLocalStorageItem } from '../localStorage';
import { capitalize } from '../misc';

export function dispatchDataCompiler(data = []) {
  let finalData = [];
  data.map((obj) => {
    let dispatchData = {
      url: obj.name.replaceAll('/', '_'),
      priority: capitalize(obj?.move_ids?.[0]?.manufacture_priority?.replaceAll("_"," ")),
      id: obj.id,
      shipment_no: obj.name,
      contact: obj?.partner_id?.name || 'N/A',
      container: obj?.container_id || 'N/A',
      delivery_contact: obj.partner_id.name || 'N/A',
      lot: obj?.lot_name || '-',
      shipment_date: obj.scheduled_date.split(' ')[0],
      box_count: obj.box_count || 'N/A',
      dispatch_status: capitalize(obj.dispatch_status),
      state: capitalize(obj.state),
      origin: obj.origin || 'N/A',
      data: obj,
    };
    finalData.push(dispatchData);
  });
  return finalData;
}

export function dispatchDetailsBox(dispatch) {
  let finalData = [
    { name: 'Name', col: 1, data: dispatch?.name },
    { name: 'Partner', col: 1, data: dispatch?.partner_id.name },
    { name: 'Source ', col: 1, data: dispatch?.origin || 'N/A' },
    dispatch?.dispatch_status !== 'draft' && {
      name: 'Container No',
      col: 1,
      data: dispatch?.container_id || 'N/A',
    },
    { name: 'Scheduled Date', col: 2, data: dispatch?.scheduled_date.split(' ')[0] },
    { name: 'Status', col: 2, data: capitalize(dispatch?.state) },
    { name: 'Dispatch Status', col: 2, data: capitalize(dispatch?.dispatch_status) },
    getLocalStorageItem(LOCAL_STORAGE.VENDOR_TYPE) === 'fabric' && {
      name: 'Priority',
      col: 2,
      data: capitalize(dispatch?.move_ids?.[0]?.manufacture_priority) || 'N/A',
    },
  ];
  return finalData;
}

export function fabricDispatchProductCompiler(data = []) {
  let finalData = [];
  const vendor_shipment = data?.vendor_shipment;
  const container = data?.container_id;
  data?.move_ids?.map((obj) => {
    let dispatchProductsData = {
      move_id: obj.id,
      vendor_shipment,
      rolls: obj?.no_of_rolls,
      lotno: vendor_shipment
        ? obj?.move_line_ids?.[0]?.lot_id?.name || 'N/A'
        : obj?.move_line_ids?.[0]?.lot_name || 'N/A',
      demand_quantity: obj.product_uom_qty,
      producing_quantity: obj.quantity,
      container,
      unit: obj.product_uom?.name,
      product: obj.product_id?.display_name,
      data: obj,
      edit: obj.subcontract_has_been_recorded,
    };
    finalData.push(dispatchProductsData);
  });
  return finalData;
}

export function dispatchCompiler(data = []) {
  let finalData = [];
  const container = data.container_id;
  data.move_ids.map((obj) => {
    let dispatchProductsData = {
      ...obj,
      container,
    };
    finalData.push(dispatchProductsData);
  });
  return finalData;
}

export function yarnDispatchProductCompiler(data = []) {
  let finalData = [];
  if (Array.isArray(data) && data.length > 0) {
    data.map((obj) => {
      let dispatchProductsData = {
        move_id: obj?.id,
        rolls: obj?.no_of_rolls,
        lotno: obj?.product_id?.tracking === 'lot' ? obj?.move_line_ids[0]?.lot_name : 'N/A',
        demand_quantity: obj?.product_uom_qty,
        producing_quantity: obj?.quantity,
        unit: obj?.product_uom?.name,
        product: obj?.product_id?.display_name,
        data: obj,
        edit: obj?.subcontract_has_been_recorded,
      };
      finalData.push(dispatchProductsData);
    });
  }

  return finalData;
}

export function shipmentDataCompiler(data) {
  let finalData = [];
  if (data?.package_level_ids) {
    data.package_level_ids.map((obj) => {
      let shipmentData = {
        package_id: obj.package_id?.id,
        BLP: obj.package_id?.name,
        SKU: obj.package_id?.quant_ids[0]?.product_id?.default_code,
        Batch: obj.package_id?.quant_ids[0]?.lot_id?.name,
        SKU_Details: obj?.package_id.quant_ids?.map((sku) => ({
          SKU: sku.product_id?.display_name,
          'Batch No.': sku.lot_id?.name,
          Quantity: sku.quantity + ' Unit',
        })),
        data: obj,
      };
      finalData.push(shipmentData);
    });
  }
  return finalData;
}
// {
//   id: 49,
//   package_id: {
//     id: 35,
//     name: 'PACK0000036',
//     quant_ids: [
//       {
//         id: 258,
//         product_id: {
//           id: 60,
//           default_code: 'ACL012X',
//           display_name: '[ACL012X] Active S/S (Black, 2XL)',
//         },
//         quantity: 40.0,
//         lot_id: {
//           id: 93,
//           name: 'ESUN/00043',
//         },
//       },
//     ],
//   },
// },
