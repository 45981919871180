import React, { useState, useEffect } from 'react';
import { Button, Input } from '../../shared';
import { DropDownInput } from '../../common';
import { useDispatch } from 'react-redux';
import { vendorModalDispatch, vendorModalSubmitDispatch } from '../../../service';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { BUTTON, DISPLAY_TEXTS } from '../../../constants';

export const FabricDispatchModal = ({ onClose, modalData, setReload }) => {
  const [productsList, setProductsList] = useState([]);
  const [rows, setRows] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [producingQuantity, setProducingQuantity] = useState(
    modalData.producing_quantity.toString(),
  );
  const dispatch = useDispatch();

  const getProductsList = useDebounce(() => {
    vendorModalDispatch({
      data: { move_id: modalData.move_id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setProductsList(response.data);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  }, 400);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getProductsList();
  }, []);

  useEffect(() => {
    const products = productsList.map((item) => ({
      move_id: item.id,
      name: item.product.toString(),
      defaultLotNo: item.selected_lot[0]?.name,
      defaultLotId: item.selected_lot[0]?.id,
      lotNumbers: item.lots.map((lot) => {
        lot.name;
      }),
      unitMultiplier: item.quantity,
    }));

    const initialRows = products.map((product) => ({
      move_id: product.move_id,
      product: product.name,
      dropdown1: product.defaultLotNo,
      defaultLotId: product.defaultLotId,
      producingQuantity: '',
      calculatedQuantity: '',
      unitMultiplier: product.unitMultiplier,
    }));

    setRows(initialRows);
  }, [productsList]);

  useEffect(() => {
    const allFilled = rows.every((row) => row.calculatedQuantity !== '');
    setIsSubmitDisabled(!allFilled);
  }, [rows]);

  const handleChange = (field, value, rowIndex) => {
    const newRows = [...rows];
    if (field === 'producingQuantity') {
      setProducingQuantity(value);
      setIsSaveDisabled(value > modalData.demand_quantity);
    } else if (field === 'dropdown1') {
      newRows[rowIndex][field] = value;
    }
    setRows(newRows);
  };

  const handleSave = () => {
    if (producingQuantity > modalData.demand_quantity) {
      return;
    }
    const newRows = rows.map((row) => ({
      ...row,
      producingQuantity,
      calculatedQuantity: (producingQuantity * row.unitMultiplier).toFixed(4),
      lot_id: row.defaultLotId,
    }));
    setRows(newRows);
  };

  const handleSubmit = useDebounce(() => {
    const data = rows.map((row) => ({
      move_line_id: row.move_id,
      lot_id: parseInt(row.lot_id),
    }));
    const finalObject = {
      move_id: modalData.move_id,
      producing_qty: parseFloat(producingQuantity),
      data: data,
    };
    vendorModalSubmitDispatch({
      data: finalObject,
    })
      .then((response) => {
        popup('success', response.message);
        dispatch(setLoaderVisibility(false));
        setReload(true);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
    onClose();
  }, 400);

  return (
    <div
      className="modal fade show d-block"
      id="fabricDispatchModal"
      tabIndex="-1"
      aria-labelledby="fabricDispatchModalLabel"
      aria-hidden="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title w-100" id="fabricDispatchModalLabel">
              {DISPLAY_TEXTS.FABRIC_DISPATCH[0]}
            </h5>
          </div>
          <div className="modal-body">
            <div className="w-100 editing-container">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="edit-container py-4 px-lg-5 px-4 table-new-div bg-white data-box w-100 h-100">
                  {/* Detail Box */}
                  <div className="detail-box-popup mb-3">
                    <div className="row mb-1">
                      <div className="col-12">
                        <strong>{DISPLAY_TEXTS.FABRIC_DISPATCH[1]}</strong> {modalData.lotno}
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-12">
                        <strong>{DISPLAY_TEXTS.FABRIC_DISPATCH[2]}</strong>{' '}
                        {modalData.demand_quantity}
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-12">
                        <div className="d-flex align-items-center">
                          <strong>{DISPLAY_TEXTS.FABRIC_DISPATCH[3]}</strong>
                          <Input
                            className="prd-qty-input"
                            value={producingQuantity}
                            onChange={(e) => handleChange('producingQuantity', e.target.value)}
                          />
                          <span className="m-1"> / {modalData.demand_quantity}</span>
                          <Button
                            className={`m-1 border-0 blue-btn align-items-center justify-content-center py-2 px-3 ${isSaveDisabled ? 'disabled' : ''}`}
                            clickHandler={handleSave}
                            disabled={isSaveDisabled}>
                            {BUTTON.SAVE}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="w-100 table-3">
                      <table className="w-100">
                        <thead>
                          <tr>
                            {DISPLAY_TEXTS.FABRIC_DISPATCH_MODAL.map((column, index) => (
                              <th className={index === 0 ? 'first-col' : 'last-col'} key={index}>
                                {column}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="inner-table-tbody-input">
                          {rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              <td className="custom-padding">
                                <div className="display-row-text">{row.product}</div>
                              </td>
                              <td className="custom-padding">
                                <DropDownInput
                                  dropdownMenu={productsList[rowIndex]?.lots.map((lot) => lot.name)}
                                  value={row.dropdown1}
                                  onChange={(value) => handleChange('dropdown1', value, rowIndex)}
                                />
                              </td>
                              <td className="custom-padding">
                                <span className="display-row-text">{row.calculatedQuantity}</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className={`border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3 ${isSubmitDisabled ? 'disabled' : ''}`}
              clickHandler={handleSubmit}
              disabled={isSubmitDisabled}>
              {BUTTON.SUBMIT}
            </Button>
            <Button
              className="border-0 grey-btn d-flex align-items-center justify-content-center py-2 px-3"
              clickHandler={onClose}>
              {BUTTON.CLOSE}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
