import React, { useEffect, useState, useRef } from 'react';
import { DetailBox, PaginatedTable, TitleBar } from '../../common';
import {
  TITLES,
  ROUTES,
  YARN_DISPATCH_INNER_COLUMN,
  POPUP_TYPE,
  POPUP_MSG,
  BUTTON,
  LOCAL_STORAGE,
} from '../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { PopUpBox, Button, Input } from '../../shared';
import {
  dispatchDetailsBox,
  yarnDispatchProductCompiler,
  fabricDispatchProductCompiler,
  dispatchCompiler,
} from '../../../utils/dataCompiler/dispatchDataCompiler';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import {
  updateReceiptQty,
  vendorReceipt,
  markDispatched,
  packageCreationDyeing,
  vendorUpdateContainerNo,
} from '../../../service';
import { setLoaderVisibility } from '../../../redux';
import { popup, getLocalStorageItem } from '../../../utils';
import PrintLabelModal from './PrintLabelModal';
import { printLabelPackage } from '../../../service/package';

export const YarnDispatchDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation(location?.state);
  const [productData, setProductData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validate, setValidate] = useState(false);
  const [popUpData, setPopUpData] = useState('');
  const [containerNo, setContainerNo] = useState('');
  const [paginationData, setPaginationData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  const getProductsList = useDebounce(() => {
    vendorReceipt({
      data: { receipt_ids: state?.id, page_number: pageNumber },
    })
      .then((response) => {
        if (response?.data?.records?.length > 0){
        dispatch(setLoaderVisibility(false));
        if (getLocalStorageItem('vendor_type') === 'dyeing') {
          setProductData(
            Array.isArray(response?.data?.records) && response?.data?.records?.length > 0
              ? fabricDispatchProductCompiler(response?.data?.records?.[0])
              : [],
          );
        } else {
          setProductData(dispatchCompiler(response?.data?.records?.[0]));
        }
        setPaginationData(response?.data?.params)
      }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  }, 400);

  useEffect(() => {
    if (productData?.length !== 0 && productData?.[0]?.container !== false) {
      setContainerNo(productData?.[0]?.container);
    }
  }, [productData]);

  const handleContainerNoChange = (event) => {
    const value = event.target.value;
    setContainerNo(value === '' ? '' : value.trimStart());
  };

  const handleContainerUpdate = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    vendorUpdateContainerNo({ data: { dispatch_id: [state?.id], container_id: containerNo } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        getProductsList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 400);

  useEffect(() => {
    if (!state) navigate(ROUTES.YARN_DISPATCH_DETAIL);

    dispatch(setLoaderVisibility(true));
    getProductsList();
  }, []);

  const handlePrintLabelClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const canShowDispatch = () => {
    if (productData?.length) {
      return productData?.every(
        (move) =>
          (move?.product_id?.tracking === 'lot' && move?.move_line_ids[0]?.lot_name !== false) ||
          move?.product_id.tracking !== 'lot',
      );
    }
  };

  const handleSubmitModal = (payload) => {
    printLabelPackage({
      data: payload,
    })
      .then((response) => {
        if (response.data.length > 0) {
          const url = response.data[0].split('?')[0];
          window.open(url, '_blank', 'noreferrer');
        }
        dispatch(setLoaderVisibility(false));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const createPackage = () => {
    dispatch(setLoaderVisibility(true));
    packageCreationDyeing({ data: { picking_id: [state?.id] } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        getProductsList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const validateDispatch = (data) => {
    dispatch(setLoaderVisibility(true));
    updateReceiptQty({ data: { ...data } })
      .then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        getProductsList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };
  const submitDispatch = () => {
    dispatch(setLoaderVisibility(true));
    markDispatched({
      data: { dispatch_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        navigate(ROUTES.DISPATCH);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  };

  let filteredCol =
  getLocalStorageItem(LOCAL_STORAGE.VENDOR_TYPE)!=='fabric'
      ? YARN_DISPATCH_INNER_COLUMN.filter((col) => col.name !== 'Rolls')
      : YARN_DISPATCH_INNER_COLUMN;
  const column =
    state?.dispatch_status !== 'draft' || state?.state === 'done'
      ? filteredCol.filter((col) => col.name !== 'Action')
      : filteredCol;

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.DISPATCH, route: ROUTES.DISPATCH },
          { text: state?.name, route: ROUTES.NULL },
        ]}
      />
      <div className="scroll-div">
        <div className="d-flex justify-content-between gap-2 mb-3">
          {state?.dispatch_status === 'draft' && (
            <div className="d-flex align-items-center justify-content-start mb-3">
              <label className="container-label">Container No</label>
              <Input
                type="text"
                placeholder="Container No"
                className={`d-flex ${containerNo === '' ? 'disabled' : ''} me-2 search-placeholder align-items-center justify-content-center container-input`}
                value={containerNo}
                onChange={handleContainerNoChange}
                readOnly={
                  !(
                    state?.dispatch_status === 'draft' &&
                    (state?.state !== 'done' || state?.state !== 'cancel')
                  )
                }
              />
              {state?.dispatch_status === 'draft' &&
                (state?.state !== 'done' || state?.state !== 'cancel') && (
                  <Button
                    className={`search-btn ${containerNo?.length === 0 || productData?.[0]?.container===containerNo ? 'disabled' : ''} d-flex align-items-center justify-content-center px-3`}
                    clickHandler={handleContainerUpdate}
                    disabled={containerNo === '' || productData?.[0]?.container===containerNo}>
                    {BUTTON.SAVE}
                  </Button>
                )}
            </div>
          )}
          {state?.dispatch_status === 'dispatched' &&
            getLocalStorageItem(LOCAL_STORAGE.VENDOR_TYPE) === 'finished_goods' &&
            (productData?.every((line) => line.packages_ids?.length === 0) ? (
              <Button
                clickHandler={createPackage}
                className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
                {BUTTON.READY_FOR_PACK}
              </Button>
            ) : (
              <Button
                clickHandler={handlePrintLabelClick}
                className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
                {BUTTON.PRINT_LABEL}
              </Button>
            ))}
          {state?.dispatch_status === 'draft' && (
            <Button
              clickHandler={submitDispatch}
              disabled={
                (getLocalStorageItem('tags')[0] !== 'dyeing' && !canShowDispatch()) ||
                productData?.[0]?.container === false ||
                productData?.[0]?.container === ''
              }
              className={`blue-btn ${getLocalStorageItem('tags')[0] !== 'dyeing' && !canShowDispatch() ? 'disabled' : ''} ${productData?.[0]?.container === false || productData?.[0]?.container === '' ? 'disabled' : ''} border-0 d-flex align-items-center justify-content-center py-2 px-3`}>
              {BUTTON.DISPATCH}
            </Button>
          )}
        </div>
        <DetailBox colCount={2} colData={state} dataCompiler={dispatchDetailsBox} />
        <PaginatedTable
          columns={column}
          initialData={productData}
          paginationData={paginationData}
          setPageNumber={setPageNumber}
          colFilter={false}
          compiler={
            getLocalStorageItem('tags')[0] !== 'dyeing'
              ? { use: true, tool: yarnDispatchProductCompiler }
              : { use: false }
          }
          popUp={validate}
          setPopUp={setValidate}
          setPopUpData={setPopUpData}
        />
        {validate && (
          <PopUpBox openPopUpBox={validate}>
            <YarnDispatchValidate
              apiCall={validateDispatch}
              setOpenPopUpBox={setValidate}
              data={popUpData}
            />
          </PopUpBox>
        )}
        {isModalOpen && (
          <PrintLabelModal
            onClose={handleCloseModal}
            onSubmit={handleSubmitModal}
            packageData={productData}
          />
        )}
      </div>
    </>
  );
};
const YarnDispatchValidate = ({ apiCall, setOpenPopUpBox, data }) => {
  const [doneQty, setDoneQty] = useState('');
  const [lotNo, setLotNo] = useState('');
  const qtyRef = useRef();
  const lotRef = useRef();
  const closePopUp = () => {
    setOpenPopUpBox(false);
  };
  const handleApiCall = () => {
    if (doneQty > 0) {
      setOpenPopUpBox(false);
      const apiData =
        data.lotno === 'N/A' && getLocalStorageItem('tags')[0] !== 'yarn'
          ? { move_id: data.move_id, done_qty: +doneQty }
          : {
              move_id: data.move_id,
              done_qty: +doneQty,
              lot_name: lotNo,
            };
      apiCall(apiData);
    } else {
      popup(POPUP_TYPE.ERR, POPUP_MSG.INVALID_QTY_MSG);
    }
  };

  useEffect(() => {
    if (lotRef.current) {
      lotRef.current.focus();
    } else {
      qtyRef.current.focus();
    }
  }, []);
  return (
    <>
      <div className="d-flex flex-column m-1 ">
        <h4 className=" text-center text-special mb-3 mt-3">
          {' '}
          Enter{' '}
          {getLocalStorageItem('tags')[0] === 'yarn' && data.lotno !== 'N/A'
            ? 'Lot and Quantity'
            : 'Quantity'}
        </h4>
        <hr />
        <div className="w-100 modal-body py-0 d-flex flex-column gap-3 justify-content-center align-items-center">
          {getLocalStorageItem('tags')[0] === 'yarn' && data.lotno !== 'N/A' && (
            <div className="d-flex justify-content-between w-100 ">
              <label className="ms-3">Lot No.</label>
              <input
                type="text"
                className="ps-2 curve-border me-4"
                placeholder={data.lotno || 'Enter lot Number'}
                ref={lotRef}
                onChange={(e) => {
                  setLotNo(e.target.value);
                }}
              />
            </div>
          )}
          <div className="d-flex justify-content-between w-100 ">
            <label className="ms-3">Quantity</label>
            <input
              type="number"
              className="ps-2 curve-border me-4"
              placeholder={data.producing_quantity || 'Enter dispatched quantity'}
              ref={qtyRef}
              onChange={(e) => {
                setDoneQty(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.UPDATE}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};
